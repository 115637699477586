<template>
    <div class="dashboard-container">
      <h2>Cierre de Caja Dashboard</h2>
      <div v-if="errorMessage">{{ errorMessage }}</div>
      
      <!-- Show total open amount and commission -->
      <div>
        <p><strong>Total Open Amount:</strong> ${{ totalAmount }}</p>
        <p><strong>Commission:</strong> ${{ commissionAmount }}</p>
      </div>
  
      <!-- List last 10 operations -->
      <h3>Last 10 Operations</h3>
      <ul>
        <li v-for="operation in lastOperations" :key="operation.idoperacion">
          Operation ID: {{ operation.idoperacion }} | Amount: ${{ operation.importe }} | Status: {{ operation.status }}
        </li>
      </ul>
  
      <!-- Button to view all operations -->
      <button @click="goToOperations">View All Operations</button>
  
      <!-- Cerrar Caja button -->
      <button @click="confirmCloseOperations">Cerrar Caja</button>
  
      <!-- Confirmation Modal -->
      <div v-if="showConfirmationModal" class="modal">
        <div class="modal-content">
          <h3>Confirm Closure</h3>
          <p>Are you sure you want to close the operations?</p>
          <button @click="closeOperations">Yes, Cerrar Caja</button>
          <button @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        totalAmount: 0,
        commissionAmount: 0,
        lastOperations: [],
        errorMessage: '',
        showConfirmationModal: false // Modal state
      };
    },
    methods: {
      // Fetch data for dashboard
      async fetchDashboardData() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/dashboard`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' // Ensure session cookies are included
          });
          if (!response.ok) throw new Error('Failed to fetch dashboard data');
          
          const data = await response.json();
          this.totalAmount = data.totalAmount;
          this.commissionAmount = data.commissionAmount;
          this.lastOperations = data.lastOperations;
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
          this.errorMessage = 'Failed to load dashboard data. Please try again.';
        }
      },
      goToOperations() {
        this.$router.push('/listarOperaciones');
      },
      // Show confirmation modal before closing operations
      confirmCloseOperations() {
        this.showConfirmationModal = true;
      },
      closeModal() {
        this.showConfirmationModal = false;
      },
      // Execute the operation closure and redirect to summary
      async closeOperations() {
   try {
     const response = await fetch(`${process.env.VUE_APP_API_URL}/closeOperations`, {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       credentials: 'include'
     });
     if (!response.ok) throw new Error('Failed to close operations');

     const result = await response.json();  // Get the closure summary
     this.showConfirmationModal = false;

     // Save the summary data in localStorage for use in the receipt component
     localStorage.setItem('closureSummary', JSON.stringify(result));

     // Redirect to CierreDeCajaReceipt.vue for summary and print options
     this.$router.push('/cierreDeCajaReceipt');
   } catch (error) {
     console.error('Error closing operations:', error);
     this.errorMessage = 'Failed to close operations. Please try again.';
   }
}

    },
    mounted() {
      this.fetchDashboardData();
    }
  };
  </script>
  
  <style scoped>
  .dashboard-container {
    margin: 20px;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  </style>
  