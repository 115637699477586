<template>
  <div class="d-flex flex-column h-100 text-center">
    <p align="right">
      <img src="@/img/logoDos.png" width="15%">
    </p>

    <main class="container">
      <div class="starter-template text-center py-5 px-3">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">¡Muchas gracias!</h5>
            <p class="card-text">Su pago ha sido procesado exitosamente.</p>
            
            <div class="row begin-countdown mt-4">
              <div class="col-md-12 text-center">
                <progress :value="countdown" max="5"></progress>
                <p>Será redirigido a la página principal en <span>{{ countdown }}</span> segundos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countdown: 5,  // Start countdown from 5 seconds
    };
  },
  mounted() {
    // Countdown timer that decreases the countdown every second
    const countdownInterval = setInterval(() => {
      this.countdown -= 1;
      if (this.countdown <= 0) {
        clearInterval(countdownInterval);
        this.$router.push('/');  // Redirect to home page after countdown
      }
    }, 1000);
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

/* Additional styles specific to this component */
</style>
