<template>
    <div class="d-flex flex-column h-100 text-center">
      <p align="right">
        <img src="@/img/logoDos.png" width="15%">
      </p>
  
      <main class="container">
        <div class="starter-template text-center py-5 px-3">
          <div class="row">
            <!-- Column for the receipt -->
            <div class="col-md-8">
              <div class="card text-center">
                <div class="card-body">
                  <h5 class="card-title">Recibo</h5>
                  <div id="recibo">
                    <center>
                      <h2>SWISSNET</h2>
                      José Rivera 440<br>
                      N3328EFO<br>
                      Jardín América - Misiones<br>
                      0810-555-7947 (lin. rot.)<br>
                      info@swiss-net.com.ar<br>
                      www.swiss-net.com.ar
                      <hr>
                      <h3>Recibo X</h3>
                      no válido como factura<br>
                      CUIT: 30-71665558-6<br>
                      ING. BRUTOS: 30-71665558-6<br>
                      <hr>
                    </center>
                    Fecha de Emisión: <label>{{ formattedDate }}</label><br>
                    Código Operación: <label>{{ codigoCobranzaDevuelto }}</label>
                    <hr>
                    <b>Cliente:</b> {{ nombreCliente }}<br>
                    {{ condicionIVACliente }}<br>
                    <b>Direccion:</b> {{ direccionCliente }}<br>
                    <b>Ciudad:</b> {{ ciudadCliente }}<br>
                    <hr>
                    <b>Recibi la suma de: $ </b>{{ totalDepositado }}<br>
                    <u>En concepto de:</u> Pago<br><br>
                    <b>Saldo en Cuenta:</b> $ {{ saldoNuevo }}<br><br>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Column for the buttons -->
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <div v-if="totalDepositado > 0">
                <button @click="printReceipt" class="btn btn-primary mb-3">Imprimir Recibo</button>
                <button @click="finishTransaction" class="btn btn-secondary">Finalizar</button>
              </div>
              <div v-else>
                <div class="row begin-countdown">
                  <div class="col-md-12 text-center">
                    <progress :value="countdown" max="5"></progress>
                    <p>Pago no ingresado. Redireccionando en <span>{{ countdown }}</span> segundos...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import printJS from 'print-js';
  
  export default {
    data() {
      return {
        saldoInicial: parseFloat(localStorage.getItem('clienteDeuda')) || 0,
        totalDepositado: parseFloat(localStorage.getItem('totalDepositado')) || 0,
        saldoNuevo: 0,  // Initialized to 0
        formattedDate: new Date().toLocaleString('es-AR'),
        nombreCliente: localStorage.getItem('clienteNombre') || '',
        direccionCliente: localStorage.getItem('clienteDireccion') || '',
        condicionIVACliente: localStorage.getItem('clienteCondicionIVA') || '',
        ciudadCliente: localStorage.getItem('clienteCiudad') || '',
        codigoCobranzaDevuelto: localStorage.getItem('codigoCobranzaDevuelto') || '',
      };
    },
    methods: {
      printReceipt() {
        printJS({
          printable: 'recibo',
          type: 'html'
        });
      },
      finishTransaction() {
        this.$router.push('/');
      },
      validateDeposit() {
        // Adjust this to ensure totalDepositado is valid
        if (this.totalDepositado <= 0) {
          return false;
        }
        return true;
      }
    },
    mounted() {
      // Calculate saldoNuevo after the component is mounted
      this.saldoInicial = parseFloat(localStorage.getItem('clienteDeuda')) || 0;
      this.totalDepositado = parseFloat(localStorage.getItem('totalDepositado')) || 0;
  
      // Adjust the logic based on whether the initial debt is positive or negative
      if (this.saldoInicial >= 0) {
        // Client has credit, add the deposit to the credit
        this.saldoNuevo = this.saldoInicial + this.totalDepositado;
      } else {
        // Client has debt, subtract the deposit from the debt
        this.saldoNuevo = this.saldoInicial - this.totalDepositado;
      }
  
      // Print the receipt only after saldoNuevo is calculated
      if (this.validateDeposit()) {
        setTimeout(() => {
          this.printReceipt();  // Automatically open the print window on page load
        }, 0); // Ensure this runs after saldoNuevo is calculated
      }
    }
  };
  </script>
  
  <style scoped> 
  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import '@/css/starter-template.css';
  @import '@/css/estilosCompartidos.css';
  </style>
  