<template>
  <div class="d-flex flex-column h-100 text-center">
    <p align="right">
      <img src="@/img/logoDos.png" width="15%">
    </p>

    <main class="container">
      <div class="starter-template text-center py-1 px-3">
        <div class="card text-center">
          <div class="card-body">
            <h2>Ingresar DNI del titular</h2>
            <br />

            <form @submit.prevent="handleFormSubmission">
              <div class="row">
                <div class="col">
                  <input
                    id="input1"
                    class="input form-control form-control-lg"
                    placeholder="DNI"
                    type="text"
                    required
                    autocomplete="off"
                    v-model="dni"
                  />
                </div>
              </div>
            </form>
            <br /><br />

            <div class="btn-group">
              <button class="btn-cancelar mr-2 rounded" @click="goHome">
                <strong>&nbsp;&nbsp;CANCELAR&nbsp;&nbsp;</strong>
              </button>
              <button class="btn ml-2 rounded" @click="handleFormSubmission">
                <strong>&nbsp;&nbsp;ACEPTAR&nbsp;&nbsp;</strong>
              </button>
            </div>
            <br /><br />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// SeleccionaCliente.vue
import { guardaDatosCliente } from '@/utils/funcionesCompartidas.js';
//import $ from 'jquery';

export default {
  data() {
    return {
      dni: '',
    };
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    async handleFormSubmission() {
      await this.guardaClienteSeleccionado();
    },
    async guardaClienteSeleccionado() {
      console.log('guardaClienteSeleccionado in');
      localStorage.setItem('usernameingresado', this.dni);
      const idCliente = localStorage.getItem('usernameingresado');
      await guardaDatosCliente(idCliente);  // This will now authenticate and then save client data
      console.log('id: ' + idCliente);
      await this.sleep(1000);
      this.$router.push('/confirmaCliente');
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  mounted() {
    document.getElementById('input1').focus();
  },
};

</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/login.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

/* Additional styles specific to this component */
</style>
