import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router configuration
//JL logging

/*import JL from 'jsnlog';
window.JL = JL;  // Attach JL to the window object */

// Example JL configuration
/*JL().setOptions({
  defaultAjaxUrl: '/logger',  // Set this to your logging endpoint if needed
  clientIP: '127.0.0.1',  // Example IP, adjust as needed
});
*/
/*import { createApp } from 'vue';
import App from './views/HomePage.vue';  // Adjust to point to your main component
import router from './router'; */

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/estilosCompartidos.css'; // Using the alias

import "@/utils/funcionesCompartidas.js";


const app = createApp(App);
app.use(router); // Use the router
app.mount('#app');


