<template>
  <div class="d-flex flex-column h-100 text-center">
    <p align="right">
      <img src="@/img/logoDos.png" width="15%">
    </p>

    <main class="container">
      <div class="starter-template text-center py-5 px-3">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">Carga Finalizada</h5>
            <div class="col-md-4 order-md-2 mb-4" style="max-width: 100rem;">
              <ul class="list-group mb-3">
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h2 class="my-0">Saldo Inicial:</h2>
                  </div>
                  <h2 class="my-0">{{ saldoInicial }}</h2>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h2 class="my-0">Total Depositado:</h2>
                  </div>
                  <h2 class="my-0">{{ totalDepositado }}</h2>
                </li>
              </ul>
            </div>
            <div class="row begin-countdown">
              <div class="col-md-12 text-center">
                <progress :value="countdown" max="5"></progress>
                <p>Redireccionando en <span>{{ countdown }}</span> segundos...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import * as funcionesCompartidas from '@/utils/funcionesCompartidas.js';

export default {
  data() {
    return {
      saldoInicial: localStorage.getItem('clienteDeuda') || 0,
      totalDepositado: parseFloat(localStorage.getItem('totalDepositado')) || 0,
      countdown: 5,
      hayConexion: localStorage.getItem('hayConexion') || 'false'
    };
  },
  methods: {
    async processPayment() {
      console.log("processPayment called");
      // Ensure the correct amount is taken from localStorage
      this.totalDepositado = parseFloat(localStorage.getItem('totalDepositado')) || 0;

      if (this.hayConexion === 'true') {
        await funcionesCompartidas.procesaDeposito();
        localStorage.setItem('estadoDeposito', 'realizado');
      } else {
        await funcionesCompartidas.postergaDeposito();
      }

      // After successful payment, create the operation
      await this.createOperation();

      this.startCountdown();
    },
   
    async createOperation() {
      console.log("createOperation called");
      const userid = localStorage.getItem('userid');
      const idcustomer = localStorage.getItem('clienteIdCustomer');
      const codigocobranza = localStorage.getItem('codigoCobranzaDevuelto');
      const fecha = new Date().toISOString();  // Adjust as needed
      const importe = this.totalDepositado;

      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userid, idcustomer, fecha, codigocobranza, importe })
        });
        if (!response.ok) throw new Error('Error creating operation');
        console.log('Operation created successfully');
      } catch (error) {
        console.error('Error creating operation:', error);
      }
    },

    startCountdown() {
      const countdownInterval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          clearInterval(countdownInterval);
          this.redirectAfterCountdown();
        }
      }, 1000);
    },

    redirectAfterCountdown() {
      this.$router.push('/pagorealizado');
    }
  },

  mounted() {
  if (!this.paymentProcessed) {
    funcionesCompartidas.chequeaServidor(); // Check server connection status
    this.processPayment(); // Begin the payment process
    this.paymentProcessed = true;
  }
}

};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';
</style>
