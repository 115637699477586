<template>
    <div class="dashboard-container">
      <p align="right">
        <img src="@/img/logoDos.png" width="15%" />
      </p>
  
      <main class="container">
        <div class="starter-template text-center py-5 px-3">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="heading-2">Cierre de Caja Dashboard</h2>
  
              <!-- Button group for actions -->
              <div class="btn-group">
                <!-- Admin Only: See All Operations Button -->
                <div v-if="isAdmin" class="my-4">
                  <button class="btn" @click="goToOperations">Ver Todas las Operaciones</button>
                </div>
                <button class="btn" @click="previewClosure">Cerrar Caja</button>
                <button class="btn" @click="goHome">Inicio</button>
              </div>
  
              <!-- Dashboard Data -->
              <div v-if="dashboardData">
                <div class="row">
                  <!-- Column 1: Total Open Amount and Total Commission -->
                  <div class="col-md-6 datoscliente">
                    <h3 class="heading-3">Resumen</h3>
                    <p><strong>Total Operaciones Abiertas:</strong> ${{ dashboardData.totalAmount }}</p>
                    <p><strong>Total Comisión:</strong> ${{ dashboardData.commissionAmount }}</p>
                  </div>
  
                  <!-- Column 2: Last 10 Operations -->
                  <div class="col-md-6">
                    <h3 class="heading-3">Últimas 10 Operaciones</h3>
                    <ul class="list-group mb-3">
                      <li
                        v-for="operation in dashboardData.lastOperations"
                        :key="operation.idoperacion"
                        class="list-group-item d-flex justify-content-between lh-condensed"
                      >
                        <div>
                          <h2 class="my-0">ID: {{ operation.idoperacion }}</h2>
                          <small class="text-muted">
                            Fecha: {{ operation.fecha }} - Monto: ${{ operation.importe }} - Estado: {{ operation.status }}
                          </small>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
  
                <!-- Confirmation Modal -->
                <div v-if="showConfirmationModal" class="modal">
                  <div class="modal-content">
                    <h3 class="heading-3">Confirmar Cierre</h3>
                    <div class="datoscliente">
                      <p>Número de operaciones: {{ previewData.numOperations }}</p>
                      <p>Monto total: ${{ previewData.totalAmount }}</p>
                      <p>Monto de comisión: ${{ previewData.commissionAmount }}</p>
                      <p>Monto restante: ${{ previewData.remainingAmount }}</p>
                    </div>
  
                    <!-- Buttons for Confirm and Cancel -->
                    <div class="btn-group">
                      <button class="btn" @click="confirmCloseOperations">Confirmar</button>
                      <button class="btn-cancelar" @click="closeModal">Cancelar</button>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Error message if data couldn't be fetched -->
              <div v-else>
                <p>{{ errorMessage || 'Cargando datos del dashboard...' }}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dashboardData: null, // Initialize as null to handle loading state
        previewData: null,
        showConfirmationModal: false,
        errorMessage: ''
      };
    },
    computed: {
      isAdmin() {
        return localStorage.getItem('userRole') === 'admin';
      }
    },
    methods: {
      goHome() {
        this.$router.push('/');
      },
      async fetchDashboardData() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/dashboard`, {
            method: 'GET',
            credentials: 'include'
          });
          if (!response.ok) throw new Error('Failed to fetch dashboard data');
          this.dashboardData = await response.json();
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
          this.errorMessage = 'Error fetching dashboard data. Please try again.';
        }
      },
      async previewClosure() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/previewCloseOperations`, {
            method: 'GET',
            credentials: 'include'
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch closure preview');
          }
  
          this.previewData = await response.json(); // Store preview data
          console.log(this.previewData); // Debugging log to ensure data is fetched
          this.showConfirmationModal = true;  // Show the confirmation modal with preview data
        } catch (error) {
          console.error('Error previewing closure:', error);
          this.errorMessage = 'Error previewing closure. Please try again.';
        }
      },
      async confirmCloseOperations() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/closeOperations`, {
            method: 'POST',
            credentials: 'include'
          });
          if (!response.ok) throw new Error('Failed to close operations');
          
          this.showConfirmationModal = false;
  
          // Store the closure summary in localStorage for the receipt
          localStorage.setItem('closureSummary', JSON.stringify(this.previewData));
          this.$router.push('/cierreDeCajaReceipt'); // Redirect to the summary/receipt page
        } catch (error) {
          console.error('Error closing operations:', error);
          this.errorMessage = 'Error closing operations. Please try again.';
        }
      },
      closeModal() {
        this.showConfirmationModal = false;
      },
      goToOperations() {
        this.$router.push('/listarOperaciones');
      }
    },
    mounted() {
      this.fetchDashboardData();
    }
  };
  </script>
  
  <style scoped>
  /* Import the shared styles */
  @import '@/css/estilosCompartidos.css';
  
  /* Additional modal styling */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    width: 100%;
  }
  
  .modal .btn-group {
    display: flex;
    justify-content: center;
  }
  
  .modal .btn-group button {
    margin: 0 10px;
  }
  </style>
  