<template>
    <div class="listar-operaciones">
      <p align="right">
        <img src="@/img/logoDos.png" width="15%" />
      </p>
  
      <main class="container">
        <div class="starter-template text-center py-5 px-3">
          <div class="card text-center">
            <div class="card-body">
              <h2 class="card-title">Listar Operaciones</h2>
  
              <!-- Filters Section -->
              <div class="datoscliente">
                <label for="status">Estado:</label>
                <select v-model="filters.status" id="status" class="form-control">
                  <option value="">Todos</option>
                  <option value="open">Abierta</option>
                  <option value="closed">Cerrada</option>
                </select>
  
                <label for="startDate">Fecha de Inicio:</label>
                <input type="date" v-model="filters.startDate" id="startDate" class="form-control" />
  
                <label for="endDate">Fecha de Fin:</label>
                <input type="date" v-model="filters.endDate" id="endDate" class="form-control" />
  
                <!-- Admin Only: Filter by User ID -->
                <div v-if="isAdmin">
                  <label for="userId">Usuario ID:</label>
                  <input
                    type="text"
                    v-model="filters.userId"
                    id="userId"
                    class="form-control"
                    placeholder="Filtrar por Usuario ID"
                  />
                </div>
                <br />
                <button class="btn" @click="fetchOperations">Aplicar Filtros</button>
              </div>
  
              <!-- Operations List -->
              <ul class="list-group mb-3" v-if="operations.length > 0">
                <li
                  v-for="operation in operations"
                  :key="operation.id"
                  class="list-group-item d-flex justify-content-between lh-condensed"
                >
                  <div>
                    <h2 class="my-0">Operación ID: {{ operation.idoperacion }}</h2>
                    <small class="text-muted">
                      Fecha: {{ operation.fecha }} - Monto: ${{ operation.importe }} - Estado: {{ operation.status }}
                    </small>
                  </div>
                </li>
              </ul>
  
              <div v-else>
                <p>No se encontraron operaciones.</p>
              </div>
  
              <!-- Pagination -->
              <div class="pagination">
                <button
                  class="btn"
                  @click="changePage(page)"
                  v-for="page in totalPages"
                  :key="page"
                  :disabled="page === currentPage"
                >
                  {{ page }}
                </button>
              </div>
  
              <div v-if="errorMessage">{{ errorMessage }}</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        operations: [],
        totalPages: 0,
        currentPage: 1,
        filters: {
          status: '',
          startDate: '',
          endDate: '',
          userId: ''
        },
        errorMessage: ''
      };
    },
    computed: {
      isAdmin() {
        return localStorage.getItem('userRole') === 'admin';
      }
    },
    methods: {
      async fetchOperations(page = 1) {
        try {
          const params = new URLSearchParams();
          params.append('page', page);
          params.append('itemsPerPage', 10);
  
          if (this.filters.status) params.append('status', this.filters.status);
          if (this.filters.startDate) params.append('startDate', this.filters.startDate);
          if (this.filters.endDate) params.append('endDate', this.filters.endDate);
          if (this.isAdmin && this.filters.userId) params.append('userId', this.filters.userId);
  
          const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones?${params}`, {
            method: 'GET',
            credentials: 'include'
          });
  
          if (!response.ok) throw new Error('Failed to fetch operations');
          const data = await response.json();
          this.operations = data.operations;
          this.totalPages = data.totalPages;
          this.currentPage = page;
        } catch (error) {
          console.error('Error fetching operations:', error);
          this.errorMessage = 'Failed to fetch operations';
        }
      },
      changePage(page) {
        this.fetchOperations(page);
      }
    },
    mounted() {
      this.fetchOperations();
    }
  };
  </script>
  
  <style scoped>
  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import '@/css/starter-template.css';
  @import '@/css/estilosCompartidos.css';
  
  .list-group-item {
    background-color: #dddddd;
    border-color: #000;
  }
  
  .pagination button {
    margin: 5px;
  }
  
  .datoscliente {
    background-color: #dddddd;
    padding: 15px;
    border-radius: 12px;
  }
  </style>
    