<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="loginUser">
      <div>
        <label for="username">Username:</label>
        <input type="text" v-model="username" id="username" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="password" id="password" required />
      </div>
      <button type="submit">Login</button>
    </form>
    <p v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nombre: this.username,
            password: this.password
          }),
          credentials: 'include' // Ensures cookies are sent and received
        });

        const data = await response.json();
        console.log('Login response:', data);  // Debugging the response

        if (data.success) {
          // Save the tokens and other data to localStorage
          localStorage.setItem('apiKey', data.apiKey);
          console.log('apiKey stored:', localStorage.getItem('apiKey'));  // Check if stored

          localStorage.setItem('apiToken', data.apiToken);
          console.log('apiToken stored:', localStorage.getItem('apiToken'));  // Check if stored

          localStorage.setItem('userid', data.userid);
          console.log('userid stored:', localStorage.getItem('userid'));  // Check if stored
         
          localStorage.setItem('userRole', data.userRole);  // Store userRole if returned
          console.log('userRole stored:', localStorage.getItem('userRole'));  // Check if stored


          // Redirect to the home page
          this.$router.push('/');
        } else {
          this.errorMessage = data.message;
        }
      } catch (error) {
        this.errorMessage = 'An error occurred during login. Please try again.';
      }
    }


 
  }
};
</script>

<style scoped>
/* Add your login page styles here */
</style>
