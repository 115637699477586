<template>
    <div class="d-flex flex-column h-100 text-center">
      <p align="right">
        <img src="@/img/logoDos.png" width="15%">
      </p>
  
      <main class="container">
        <div class="starter-template text-center py-1 px-3">
          <div class="card text-center">
            <div class="card-body">
              <h5 class="card-title">Confirmar titular de la cuenta</h5>
  
              <div class="datoscliente">
                <h1>Cliente: <strong>{{ clienteNombre }}</strong></h1>
                <br />
                <h2>Saldo actual: <strong :style="{ color: deudaColor }">{{ clienteDeuda }}</strong></h2>
              </div>
  
              <p>
                <br /><br />
                <center>
                  <button class="btn btn-success mr-2 rounded" @click="goTo('seleccionaCliente')">
                    <strong>&nbsp;&nbsp;VOLVER A INTENTAR&nbsp;&nbsp;</strong>
                  </button>
                  <button class="btn btn-success mr-2 rounded" @click="goTo('pagarDeuda')">
                    <strong>&nbsp;&nbsp;CONFIRMAR&nbsp;&nbsp;</strong>
                  </button>
                </center>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>

  export default {
    data() {
      return {
        clienteNombre: '',
        clienteDeuda: '',
        deudaColor: '',
      };
    },
    methods: {
      goTo(route) {
        this.$router.push(`/${route}`);
      },
      initializeClienteData() {
        this.clienteNombre = localStorage.getItem('clienteNombre');
        const deuda = parseFloat(localStorage.getItem('clienteDeuda'));
        this.clienteDeuda = `$ ${deuda.toFixed(2)}`;
        this.deudaColor = deuda >= 0 ? 'blue' : 'red';
  
        // Log client data
      //  this.logClienteData();
      },
     /* logClienteData() {
        const JL = window.JL;
        // Check if JL is properly initialized
        if (JL) {
        const logger = JL('totemWebApp');
        logger.info(`idcliente: ${localStorage.getItem('clienteId')}, idcustomer: ${localStorage.getItem('clienteIdCustomer')}, cliente: ${localStorage.getItem('clienteNombre')}, deuda inicial: ${localStorage.getItem('clienteDeuda')}`);
        } else {
        console.error('JSNLog is not initialized');
        }
      },*/
    },
    mounted() {
      this.initializeClienteData();
    },
  };
  </script>
  
  <style scoped>
  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import '@/css/starter-template.css';
  @import '@/css/estilosCompartidos.css';
  
  /* Additional styles specific to this component */
  </style>
  