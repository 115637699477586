<template>
  <div class="d-flex flex-column h-100 text-center">
    <p align="right">
      <img src="@/img/logoDos.png" width="15%">
    </p>

    <main class="container">
      <div class="starter-template text-center py-1 px-3">  
        <div class="card text-center">                      
          <div class="card-body">            
            <div class="row">
              <div class="col">
                <h5 class="card-title">Pagar Deuda</h5>
              </div>

              <div class="col datoscliente text-left">
                Cliente: <strong>{{ clienteNombre }}</strong><br>
                Total pendiente: <strong>{{ formattedTotalPendiente }}</strong><br>
                Monto a pagar: 
                <input 
                  type="number" 
                  v-model.number="totalDepositado" 
                  :style="{ color: deudaColor }"
                  class="form-control"
                />
              </div>
            </div>
            <br /><br /><br />

            <div class="btn-group">
              <button class="btn-cancelar mr-2 rounded" @click="goHome">
                <strong>&nbsp;&nbsp;CANCELAR&nbsp;&nbsp;</strong>
              </button>
              <button class="btn ml-2 rounded" @click="setAmountAndGoTo('realizarPago')">
                <strong>&nbsp;&nbsp;PAGAR&nbsp;&nbsp;</strong>
              </button>
            </div>
          </div>        
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clienteNombre: localStorage.getItem('clienteNombre') || '',
      clienteDeuda: parseFloat(localStorage.getItem('clienteDeuda')) || 0,
      totalDepositado: 0,
      deudaColor: '',
    };
  },
  computed: {
    formattedDeuda() {
      return `$ ${this.totalDepositado.toFixed(2)}`;
    },
    formattedTotalPendiente() {
      return `$ ${this.clienteDeuda.toFixed(2)}`;
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    setAmountAndGoTo(route) {
      localStorage.setItem('totalDepositado', this.totalDepositado); // Save the manually entered amount
      this.$router.push(`/${route}`);
    },
    updateUI() {
      // Keep totalDepositado positive and remove *-1
      this.totalDepositado = Math.abs(this.clienteDeuda);

      // Set the debt color based on value (positive or negative logic can still be used if needed)
      this.deudaColor = this.clienteDeuda >= 0 ? 'blue' : 'red';
    }
  },
  mounted() {
    this.updateUI();
  }
};
</script>



<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

/* Additional styles specific to this component */
</style>
