<template>
    <div class="receipt-container">
      <p align="right">
        <img src="@/img/logoDos.png" width="15%">
      </p>
  
      <main class="container">
        <div class="starter-template text-center py-5 px-3">
          <div class="row">
            <!-- Receipt Column -->
            <div class="col-md-8">
              <div class="card text-center">
                <div class="card-body">
                  <h5 class="card-title">Resumen de Cierre de Caja</h5>
                  <div id="cierreReceipt">
                    <center>
                      <h2>SWISSNET</h2>
                      José Rivera 440<br>
                      Jardín América - Misiones<br>
                      info@swiss-net.com.ar<br>
                      <hr>
                      <h3>Resumen del Cierre</h3>
                      <hr>
                    </center>
                    <p>Fecha de Cierre: <label>{{ formattedDate }}</label></p>
                    <p>Número de operaciones cerradas: {{ closureSummary.numOperations }}</p>
                    <p>Monto total: $ {{ closureSummary.totalAmount }}</p>
                    <p>Monto de comisión: $ {{ closureSummary.commissionAmount }}</p>
                    <p>Monto restante: $ {{ closureSummary.remainingAmount }}</p>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Buttons Column -->
            <div class="col-md-4 d-flex flex-column justify-content-center">
              <button @click="printReceipt" class="btn btn-primary mb-3">Imprimir Resumen</button>
              <button @click="finishTransaction" class="btn btn-secondary">Finalizar</button>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import printJS from 'print-js';
  
  export default {
    data() {
      return {
        closureSummary: JSON.parse(localStorage.getItem('closureSummary')) || {},
        formattedDate: new Date().toLocaleString('es-AR'),
      };
    },
    methods: {
      printReceipt() {
        printJS({
          printable: 'cierreReceipt',
          type: 'html'
        });
      },
      finishTransaction() {
        // Clear localStorage and redirect to the dashboard
        localStorage.removeItem('closureSummary');
        this.$router.push('/cierreDeCajaDashboard');
      }
    }
  };
  </script>
  
  <style scoped>
  .receipt-container {
    margin: 20px;
  }
  </style>
  